@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}
.no-border-radius {
  border-radius: 0;
}

.no-border {
  border: none !important;
}

.blue {
  color: blue !important;
}

.mr-200px {
  margin-right: 200px !important;
}

.leaflet-control {
  margin: 0 !important;
}

.overflow-visible {
  overflow: visible !important;
}

.react-date-picker__calendar {
  width: auto !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.custom-scrollbar-1 {
  scrollbar-width: auto;
  scrollbar-color: #404040 rgba(255, 255, 255, 0);
}

/* Chrome, Edge, and Safari */
.custom-scrollbar-1::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.custom-scrollbar-1::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

.custom-scrollbar-1::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid rgba(255, 255, 255, 1);
}

/* ===== Scrollbar CSS ===== */

.bg-opacity {
  background: rgba(255, 255, 255, 0.8);
}

#range-style-1::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  background-color: rgb(223, 223, 223);
  border: 1px solid black;
  border-radius: 0rem;
  height: 1.25rem;
  width: 0.5rem;
}
