
.react-datepicker__input-container input {
    display: block;
    width: 250px;
    /*margin: 0 3px;*/
}
.cross-range-datetime{
    display: flex;
    margin: 0 10px;
    align-items: center;
}
.range-datetime-div{
    margin-top: 10px;
}
