#dashboard-header {
  position: absolute;

  height: 8vh;
}

#dashboard-sidebar {
  position: absolute;

  margin-top: 8vh;
  /* width: 16rem; */
}

#dashboard-content {
  position: absolute;

  /* margin-left: 16rem; */
  margin-top: 8vh;
  /* width: calc(100vw - 16rem); */
  height: calc(100vh - 8vh);
}

.filter-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter-container select {
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 200px;
}

.create-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.create-button:hover {
  background-color: #0056b3;
}

