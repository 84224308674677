.active, .btn:hover {
    background-color: #666;
    color: white;
    height: 100%;
  }
.btn-active{
    background-color: #015CAC;
    color: white;
    height: 100%;
}

.btn{
    background-color: white;
    color: #015CAC;
}

.react-datepicker-wrapper, .react-datepicker__input-container, .react-datepicker__input-container input{
    margin-right: unset;
    margin-top: unset;
}

